.makeStyles-avatar-4 {
    margin: 8px !important;
    background-color: rgb(126, 57, 251) !important;
}

.MuiButton-containedPrimary {
    color: #fff !important;
    background-color: rgb(126, 57, 251) !important;
}

.avatar {
    backgroundColor: rgb(126, 57, 251) !important;
}

/* Container holding the image and the text */
.container {
    position: relative;
    text-align: center;
    color: white;
}

.SignInSide-avatar-4 {
    margin: 8px;
    background-color: #3f51b5 !important;
}

/* Centered text */
.centered {
    margin-top: 120px;
    position: absolute;
    top: 130%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #363a70;
    font-size: 30px;

}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;

}

.MuiInputBase-input {

    margin: 8px !important;

}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #3f51b5 !important;
}

.MuiFormControlLabel-label{
    color: rgba(0, 0, 0, 0.54) !important;
}

.hidden { display:none; }