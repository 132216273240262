.App {
  text-align: center;
}

.PrivateTabIndicator-colorSecondary-433 {
  background-color: #3f51b5 !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.otf) format('truetype');
}
​
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-ExtraBold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.otf) format('truetype');
}


@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-SemiBold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Thin';
  src: local('Gilroy-Thin'), url(./fonts/Gilroy-Thin.otf) format('truetype');
}
body {
  margin: 0;
  font-family: Gilroy-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #my-content { display: block; }  /* show it on small screens */
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
  #my-content { display: none; }   /* hide it elsewhere */
}

@media screen and (max-width: 501px) {
  #my-content { display: none; }   /* hide it elsewhere */
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
  #my-content { display: none; }   /* hide it elsewhere */
}



