body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.star{
  color:red;
}

.css-df17o1 {
  color:#f2effc !important;
  background: rgba(0.1, 0, 0, 0.0) !important;
  opacity: 1!important;;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.2)!important;
}


